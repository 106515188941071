<template>
  <div id="manageRole" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Aircraft Fueling</div>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Flight Detail</div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field label="Ref No." disabled></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field
                  label="Flight No."
                  v-model="DataFromDB.f_flight_no"
                  disabled
                ></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-combobox
                  class="hideMessage"
                  label="Customer"
                  v-model="DataFromDB.o_name"
                  disabled
                ></v-combobox>
              </div>
              <div class="box-S2">
                <v-combobox
                  class="hideMessage"
                  label="Type Of Flight"
                  v-model="DataFromDB.f_type_of_flight"
                  disabled
                ></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  class="hideMessage"
                  label="Terminal"
                  v-model="DataFromDB.tm_name"
                  disabled
                ></v-combobox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  label="Pilot"
                  v-model="DataFromDB.f_pilot_name"
                  disabled
                ></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  class="hideMessage"
                  label="Co-Pilot"
                  v-model="DataFromDB.f_copilot_name"
                  disabled
                ></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  class="hideMessage"
                  label="Aircraft"
                  v-model="DataFromDB.ac_name"
                  disabled
                ></v-combobox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>

      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Departure</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  class="hideMessage"
                  label="Departure Airport"
                  v-model="DataFromDB.departairport"
                  disabled
                ></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field
                  label="STD"
                  v-model="DataFromDB.ap_depart_date"
                  disabled
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Arrival</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-combobox
                  class="hideMessage"
                  label="Arrival Airport"
                  v-model="DataFromDB.deestairport"
                  disabled
                ></v-combobox>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field
                  label="STA"
                  v-model="DataFromDB.ap_dest_date"
                  disabled
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>

      <!-- Data payload -->
      <CompAddPassengerOutbound
        ref="CompAddPassengerOutbound"
        @SumDataBodyWeightOutbound="BodyOut"
        @SumDataBaggageWeightOutbound="BaggageOut"
        v-show="false"
      ></CompAddPassengerOutbound>
      <CompAddPassengerInbound
        ref="CompAddPassengerInbound"
        @SumDataBodyWeightInbound="BodyIn"
        @SumDataBaggageWeightInbound="BaggageIn"
        v-show="false"
      ></CompAddPassengerInbound>

      <div class="box-S4">
        <div class="N-Page T-size-24">Flight Destination</div>
      </div>
      <div class="box-S4">
        <div class="warp-destiantionShow">
          <div class="box-S1">
            <!-- Leg เริ่มต้น -->
            <div class="b-destiantionShow start">
              <div class="B-carddetail">
                <div class="b-content">
                  <i class="I-departure"></i>
                  <div class="t-name T-size-20 SemiBold">{{ this.DataFromDB.departairport }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="box-S1" v-for="item in 6" :key="item"> -->
          <div class="box-S1" v-for="item in ArrCountLegs" :key="item">
            <!-- Loop ชุดนี้ -->
            <div class="b-destiantionShow">
              <div class="B-carddetail">
                <div class="b-content">
                  <div class="t-name T-size-20 SemiBold">
                    {{ item.ap_name }}
                  </div>
                  <div class="numderPassenger">
                    <div class="t-up T-size-24">
                      <span>{{ item.l_ob | setzoro }}</span>
                      <i class="I-down"></i>
                    </div>
                    <div class="t-down T-size-24">
                      <i class="I-up"></i>
                      <span>{{ item.l_ib | setzoro }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-S1">
            <!-- Leg จบ -->
            <div class="b-destiantionShow end">
              <div class="B-carddetail">
                <div class="b-content">
                  <i class="I-arrival"></i>
                  <div class="t-name T-size-20 SemiBold">{{ this.DataFromDB.deestairport }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="line"></div>

      <div class="box-S4">
        <div class="N-Page T-size-24">Payload</div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-bodyweight"></span>
            </div>
            <div class="b-text">
              <p>Body Weight</p>
              <p class="T-size-20 SemiBold">
                {{ sumbodyweight.toFixed(2) }} Kg.
              </p>
              <!-- <p class="T-size-20 SemiBold"  v-if="mode == 'Create'">0.00 Kg.</p>
              <p class="T-size-20 SemiBold" v-else>{{itemsCalWeightFlight.body_weight[0].toFixed(2)}} Kg.</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-baggageweight"></span>
            </div>
            <div class="b-text">
              <p>Baggage Weight</p>
              <p class="T-size-20 SemiBold">
                {{ sumbaggageweight.toFixed(2) }} Kg.
              </p>
              <!-- <p class="T-size-20 SemiBold"  v-if="mode == 'Create'">0.00 Kg.</p>
              <p class="T-size-20 SemiBold" v-else>{{itemsCalWeightFlight.baggage_weight[0].toFixed(2)}} Kg.</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-cargo"></span>
            </div>
            <div class="b-text">
              <p>Cargo Weight</p>
              <!-- <p class="T-size-20 SemiBold"  v-if="mode == 'Create'">0.00 Kg.</p>
                <p class="T-size-20 SemiBold" v-else>{{itemsCalWeightFlight.cargo_weight[0].toFixed(2)}} Kg.</p> -->
              <p class="T-size-20 SemiBold">{{ sumcargo.toFixed(2) }} Kg.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <span class="icon I-totalweight"></span>
            </div>
            <div class="b-text">
              <p>Total Weight</p>
              <p class="T-size-20 SemiBold">{{ sumTotal.toFixed(2) }} Kg.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>

      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-24">Fueling</div>
        <v-btn
          class="theme-btn-even"
          @click.stop="addItem()"
          :disabled="readonly_shore || readonly_flight || readonly_pilot"
        >
          <span class="I-create"></span>
          <span>Top Up Fuel</span>
        </v-btn>
      </div>

      <div class="box-S1">
        <div class="B-carddetail">
          <div class="b-detail">
            <div class="b-icon">
              <v-icon style="margin: 10px; font-size: 38pt">mdi-fuel</v-icon>
            </div>
            <div class="b-text">
              <p>Total</p>
              <p class="T-size-20 SemiBold">{{ total.toFixed(2) }} lts.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="box-S4">
        <v-data-table :headers="headers" :items="itemsdata">
          <template v-slot:item.md="{ item }">
            <div v-if="item.fus_name != 'TOTAL'">
              {{ item.md | yyyyMMddHHmm }}
            </div>
          </template>

          <template v-slot:item.action="{ item }" class="text-xs-right">
            <div class="b-action">
              <div class="b-btn">
                <v-btn
                  fab
                  class="btn-edit"
                  @click="editItem(item)"
                  :disabled="
                    readonly_shore || readonly_flight || readonly_pilot
                  "
                >
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn
                  fab
                  class="primary btn-delete"
                  @click="deleteItem(item)"
                  :disabled="
                    readonly_shore || readonly_flight || readonly_pilot
                  "
                >
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- dialog สำหรับ เพิ่ม และ แก้ไข -->
    <v-dialog v-model="dialogEvent" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <!-- set form กรอกข้อมูลที่นี้ -->
              <v-select
                :items="itemsListFuelStation"
                v-model="fuelStation"
                label="Fuel Station"
                item-text="fus_name"
                item-value="fus_id"
                return-object
              ></v-select>
              <v-flex>
                <v-text-field
                  v-model="fu_value"
                  label="Value (lts)"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="saveToServer">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog สำหรับลบ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                >Delete "{{ fuelStation.fus_name }} {{ fu_value }} lts"
                ?</v-flex
              >
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="deleteToServer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import fillerdate from "../utils/filterdate";
import {
  startOfDay,
  endOfDay,
  format,
  addHours,
  addMinutes,
  differenceInMinutes,
} from "date-fns";

//Add
import CompAddPassengerOutbound from "../components/flight/CompAddPassengerOutboundFlight";
import CompAddPassengerInbound from "../components/flight/CompAddPassengerInboundFlight";

export default {
  data: () => ({
    userdata: "",
    headers: [
      // { value: "fu_id", text: "Id", sortable: true },
      { value: "fus_name", text: "Station", sortable: true },
      { value: "ap_name", text: "Location", sortable: true },
      { value: "fu_value", text: "Value (lts)", sortable: true },
      { value: "mb", text: "By", sortable: true },
      { value: "md", text: "Time", sortable: true },
      { value: "action", text: "", sortable: false },
    ],
    defaultValue: {
      fu_value: "",
    },
    query: { $sort: { r_id: -1 } },
    //--end config
    itemsdata: [],
    loading: false,
    dialogEvent: false,
    dialogDelete: false,
    formModel: {},
    mode: "", // มีได้ 2 แบบคือ create กับ edit

    idFlightNow: 0,
    itemsListFuelStation: [],
    fuelStation: "",
    fu_value: "",
    id: 0,
    total: 0,

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,

    DataFromDB: null,
    ArrCountLegs: [],

    //Add
    sumbodyweight: 0,
    sumbaggageweight: 0,
    sumcargo: 0,
    sumTotal: 0,
    sumfromOutboundPassenger: 0,
    sumfromInboundPassenger: 0,
    sumfromOutboundPassengerBaggage: 0,
    sumfromInboundPassengerBaggage: 0,
  }),
  props: ["mode", "idflight"],
  components: {
    CompAddPassengerOutbound,
    CompAddPassengerInbound,
  },
  computed: {
    formTitle() {
      return this.mode === "create" ? "Top Up Fuel" : "Edit Fuel";
    },
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    await this.enable_disable();
    this.idFlightNow = this.$route.params.idflight;
    //init here
    await this.renderUI();
    await this.DataPassengerFromDB();
  },
  methods: {
    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = false;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      }
    },
    async renderUI() {
      await this.RenderDataFlightFromDB();
      this.countleg();
      try {
        this.id = 0;
        this.itemsdata = [];
        const q = {};
        q.f_id = this.idFlightNow;
        var res = await feathersClientUOA
          .service("viewflightfuel")
          .find({ query: q });
        this.itemsdata = res.data;
        this.total = 0;
        res.data.forEach((element) => {res
          this.total += element.fu_value;
          //this.itemsdata.push({"fu_id": element.fu_id, "fus_id":element.fus_id,"fus_name": element.fus_name, "fu_value": element.fu_value, "cd": element.cd});
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async SelectFuelStation() {
      try {
        const q = {};
        q.retire = 0;
        var res = await feathersClientUOA
          .service("fuelstation")
          .find({ query: q });
        this.itemsListFuelStation = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      await this.SelectFuelStation();

      this.itemsdata.forEach(element => {
        this.itemsListFuelStation = this.itemsListFuelStation.filter(x => x.fus_id != element.fus_id);
      });

      this.mode = "create";
      this.fu_value = "";
      this.fuelStation = this.itemsListFuelStation[0];
      this.dialogEvent = true;
    },

    async editItem(item) {
      this.mode = "edit";
      await this.SelectFuelStation();

      this.itemsdata.forEach(element => {
        if(element.fus_id != item.fus_id){
          this.itemsListFuelStation = this.itemsListFuelStation.filter(x => x.fus_id != element.fus_id);
        }
      });

      this.id = item.fu_id;
      this.fuelStation = { fus_id: item.fus_id, fus_name: item.fus_name };
      this.fu_value = item.fu_value;
      this.dialogEvent = true;
    },
    async deleteItem(item) {
      this.id = item.fu_id;
      this.fuelStation = { fus_id: item.fus_id, fus_name: item.fus_name };
      this.fu_value = item.fu_value;
      this.dialogDelete = true;
    },

    closeDialog() {
      this.id = 0;
      this.dialogEvent = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      this.loading = true;
      if (this.mode === "edit") {
        try {
          let dataEdit = {
            fu_value: this.fu_value,
            f_id: this.idFlightNow,
            fus_id: this.fuelStation.fus_id,
            mb: this.userdata.FullName,
            md: new Date(),
          };
          await await feathersClientUOA
            .service("flightfuelnew")
            .patch(this.id, dataEdit);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        //Add Data
        try {
          var dateTime = new Date();

          let dataAdd = {
            fu_value: this.fu_value,
            f_id: this.idFlightNow,
            fus_id: this.fuelStation.fus_id,
            cb: this.userdata.FullName,
            cd: dateTime,
            mb: this.userdata.FullName,
            md: dateTime,
          };

          await feathersClientUOA.service("flightfuelnew").create(dataAdd);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        let dataDelete = {
          mb: this.userdata.FullName,
          md: new Date(),
          retire: 1,
        };
        await feathersClientUOA
          .service("flightfuelnew")
          .patch(this.id, dataDelete);
        this.renderUI();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    },
    Back() {
      this.$router.go(-1);
    },

    async RenderDataFlightFromDB() {
      try {
        var querydata = {};
        querydata.f_id = this.idFlightNow;

        var res = await feathersClientUOA
          .service("viewflight")
          .find({ query: querydata });
        this.DataFromDB = res.data[0];
        this.DataFromDB.ap_depart_date =
          this.DataFromDB.ap_depart_date.substring(0, 10) +
          " " +
          this.DataFromDB.ap_depart_date.substring(11, 16);
        this.DataFromDB.ap_dest_date =
          this.DataFromDB.ap_dest_date.substring(0, 10) +
          " " +
          this.DataFromDB.ap_dest_date.substring(11, 16);
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async countleg() {
      try {
        var q = {};
        q.f_id = this.idFlightNow;
        var res = await feathersClientUOA
          .service("viewcountleg")
          .find({ query: q });
        this.ArrCountLegs = res.data;
      } catch (error) {
        console.log(error);
      }
    },

    //Add
    async BodyOut(dataOut) {
      this.sumfromOutboundPassenger = dataOut;
      this.BodyWeightInAndOut();
    },
    async BodyIn(dataIn) {
      this.sumfromInboundPassenger = dataIn;
      this.BodyWeightInAndOut();
    },
    async BaggageOut(dataOut) {
      this.sumfromOutboundPassengerBaggage = dataOut;
      this.BaggageWeightInAndOut();
    },
    async BaggageIn(dataIn) {
      this.sumfromInboundPassengerBaggage = dataIn;
      this.BaggageWeightInAndOut();
    },
    async BodyWeightInAndOut() {
      this.sumbodyweight =
        parseFloat(this.sumfromInboundPassenger) +
        parseFloat(this.sumfromOutboundPassenger);
      this.SumDataTotal();
    },
    async BaggageWeightInAndOut() {
      this.sumbaggageweight =
        parseFloat(this.sumfromOutboundPassengerBaggage) +
        parseFloat(this.sumfromInboundPassengerBaggage);
      this.SumDataTotal();
    },
    async SumDataCargo() {
      this.sumcargo = 0;
      this.listAddCargo.forEach((element) => {
        this.sumcargo += parseFloat(element.cg_weight);
      });
      this.sumcargo = parseFloat(this.sumcargo);
      this.SumDataTotal();
    },
    async SumDataTotal() {
      this.sumTotal =
        parseFloat(this.sumcargo) +
        parseFloat(this.sumbodyweight) +
        parseFloat(this.sumbaggageweight);
    },

    async DataPassengerFromDB() {
      try {
        await this.$refs.CompAddPassengerOutbound.DataPassengerFromDB(
          this.idFlightNow
        );
      } catch (error) {
        console.log(error);
      }
      try {
        await this.$refs.CompAddPassengerInbound.DataPassengerFromDB(
          this.idFlightNow
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>